<script setup lang="ts">

const props = withDefaults( defineProps<{
  cookieDurationDays? : number
}>(), {
  cookieDurationDays: 7,
} );

const isOpen = ref( false );

const hideTrackingCookieName = "30362698-6bf7-4171-9683-6d0277ca2a8d";

const { getLocation } = useGeoTrackingResource();
const hideTrackingCookie = useCookie<boolean>( hideTrackingCookieName, {
  maxAge: props.cookieDurationDays * 24 * 60 * 60,
} );

const cloudflareCountryCodes = ["XX", "T1", "UNKNOWN"];
const userLocation = ref<string>( "NOT_SET" );

async function getUserLocation () : Promise<string> {
  let location = "UNKNOWN";

  try {
    location = ( await getLocation() ) || "UNKNOWN";
    if ( window.location.host.toLowerCase().includes( "edcowebsites" ) ) {
      console.log( "Geo tracking location:", location );
    }
  } catch ( err : any ) {
    console.error( "Unable to get user location", err );
  }

  return location;
}

onMounted( async () => {
  userLocation.value = await getUserLocation();
} );

const isInternationalUser = computed( () => !["GB", "IE"].includes( userLocation.value ) );
const isInternationalSite = computed( () => {
  const host = window.location.host.toLowerCase();
  return host.includes( "education" ) || host.includes( "int" );
} );

// eslint-disable-next-line max-len
const userLocationMatchesSiteLocation = computed( () => isInternationalUser.value === isInternationalSite.value );

const alternativeSiteDomain = computed( () => {
  let result;
  const host = window.location.host.toLowerCase();

  if ( host.includes( "dev" ) || host.includes( "localhost" ) ) {
    result = isInternationalSite.value
      ? "https://apollo-gl-support-dev.edcowebsites.co.uk/"
      : "https://apollo-gl-support-dev-int.edcowebsites.co.uk/";
  } else if ( host.includes( "staging" ) ) {
    result = isInternationalSite.value
      ? "https://apollo-gl-support-staging.edcowebsites.co.uk/"
      : "https://apollo-gl-support-staging-int.edcowebsites.co.uk/";
  } else {
    result = isInternationalSite.value
      ? "https://support.gl-assessment.co.uk/"
      : "https://support.gl-education.com/";
  }

  return result;
} );

watch( userLocation, ( val ) => {
  if ( userLocationMatchesSiteLocation.value ) {
    return;
  }

  if ( cloudflareCountryCodes.includes( val ) ) {
    return;
  }

  if ( hideTrackingCookie.value ) {
    return;
  }

  isOpen.value = true;
} );

function onConfirm () {
  window.location.href = alternativeSiteDomain.value;
}

function onCancel () {
  hideTrackingCookie.value = true;
  isOpen.value = false;
}

const friendlySiteLocation = computed( () => ( isInternationalSite.value ? "International" : "UK & Ireland" ) );
const friendlySiteLocationAlt = computed( () => ( isInternationalSite.value ? "UK & Ireland" : "International" ) );

const modalTitle = computed( () => `You are currently browsing our ${friendlySiteLocation.value} site` );

const modalDescription = computed( () => `Would you like to switch to our ${friendlySiteLocationAlt.value} site?` );

</script>

<template>
  <CustomModal
    v-model="isOpen"
    disable-close-on-click>
    <template #header>
      {{ modalTitle }}
    </template>

    {{ modalDescription }}

    <template #footer>
      <a-button
        variant="secondary"
        no-suffix
        @click="onCancel">
        No
      </a-button>
      <a-button
        variant="primary"
        no-suffix
        @click="onConfirm">
        Yes
      </a-button>
    </template>
  </CustomModal>
</template>

<style lang="scss">

</style>
