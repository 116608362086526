import { defineNuxtPlugin, useRouter } from "nuxt/app";
import { createGtm, type VueGtmUseOptions } from "@gtm-support/vue-gtm";
import { useRequestURL } from "#imports";

// @ts-ignore
export default defineNuxtPlugin( ( nuxt ) => {
  const url = useRequestURL();

  const isLocalhost = url.host.includes( "localhost" ) || url.host.includes( "127.0.0.1" );

  if ( process.client && !isLocalhost ) {
    const options = nuxt.$config.public.gtm;

    const ukCode = "GTM-MZ4HHJS";
    const intCode = "GTM-MTDJD7H";

    const culture = useCurrentCulture();
    if ( culture.value === "en" ) {
      options.id = intCode;
    } else {
      options.id = ukCode;
    }

    const router = useRouter();

    const pluginOptions: VueGtmUseOptions = {
      ...options,
      vueRouter: options.enableRouterSync && router ? router as VueGtmUseOptions["vueRouter"] : undefined,
    };

    nuxt.vueApp.use( createGtm( pluginOptions ) );
  }
} );
