import { defineNuxtPlugin } from '#app/nuxt'
import { LazyTemplateAssessmentArea, LazyTemplateAssessmentArticle, LazyTemplateAssessmentChapterMeta, LazyTemplateAssessmentChapter, LazyTemplateAssessmentItem, LazyTemplateContentItem, LazyTemplateGuideArea, LazyTemplateGuideArticle, LazyTemplateGuideChapterMeta, LazyTemplateGuideChapter, LazyTemplateGuideItem, LazyTemplateHome, LazyTemplateKnowledgebaseArea, LazyTemplateKnowledgebaseSearch, LazyTemplatePlatformArea, LazyTemplatePlatformArticle, LazyTemplatePlatformChapterMeta, LazyTemplatePlatformChapter, LazyTemplatePlatformItem, LazyTemplateSupportArticle, LazyTemplateSupportChapterMeta, LazyTemplateSupportChapter, LazyTemplateSupportItem, LazyBlockArticleSection, LazyBlockCopy, LazyBlockEmbed, LazyBlockHtml, LazyBlockImage, LazyBlockOneByTwoColumnLayout, LazyBlockQuote, LazyBlockTwoByOneColumnLayout, LazyBlockTwoColumnLayout, LazyControlApolloGridAccordionEditor, LazyControlApolloGridCarouselEditor, LazyControlApolloGridCopyEditor, LazyControlApolloGridHeadingEditor, LazyControlApolloGridQuoteEditor, LazyControlApolloGridRichTextEditor, LazyControlHeadline, LazyControlMedia, LazyControlQuote, LazyControlRte } from '#components'
const lazyGlobalComponents = [
  ["TemplateAssessmentArea", LazyTemplateAssessmentArea],
["TemplateAssessmentArticle", LazyTemplateAssessmentArticle],
["TemplateAssessmentChapterMeta", LazyTemplateAssessmentChapterMeta],
["TemplateAssessmentChapter", LazyTemplateAssessmentChapter],
["TemplateAssessmentItem", LazyTemplateAssessmentItem],
["TemplateContentItem", LazyTemplateContentItem],
["TemplateGuideArea", LazyTemplateGuideArea],
["TemplateGuideArticle", LazyTemplateGuideArticle],
["TemplateGuideChapterMeta", LazyTemplateGuideChapterMeta],
["TemplateGuideChapter", LazyTemplateGuideChapter],
["TemplateGuideItem", LazyTemplateGuideItem],
["TemplateHome", LazyTemplateHome],
["TemplateKnowledgebaseArea", LazyTemplateKnowledgebaseArea],
["TemplateKnowledgebaseSearch", LazyTemplateKnowledgebaseSearch],
["TemplatePlatformArea", LazyTemplatePlatformArea],
["TemplatePlatformArticle", LazyTemplatePlatformArticle],
["TemplatePlatformChapterMeta", LazyTemplatePlatformChapterMeta],
["TemplatePlatformChapter", LazyTemplatePlatformChapter],
["TemplatePlatformItem", LazyTemplatePlatformItem],
["TemplateSupportArticle", LazyTemplateSupportArticle],
["TemplateSupportChapterMeta", LazyTemplateSupportChapterMeta],
["TemplateSupportChapter", LazyTemplateSupportChapter],
["TemplateSupportItem", LazyTemplateSupportItem],
["BlockArticleSection", LazyBlockArticleSection],
["BlockCopy", LazyBlockCopy],
["BlockEmbed", LazyBlockEmbed],
["BlockHtml", LazyBlockHtml],
["BlockImage", LazyBlockImage],
["BlockOneByTwoColumnLayout", LazyBlockOneByTwoColumnLayout],
["BlockQuote", LazyBlockQuote],
["BlockTwoByOneColumnLayout", LazyBlockTwoByOneColumnLayout],
["BlockTwoColumnLayout", LazyBlockTwoColumnLayout],
["ControlApolloGridAccordionEditor", LazyControlApolloGridAccordionEditor],
["ControlApolloGridCarouselEditor", LazyControlApolloGridCarouselEditor],
["ControlApolloGridCopyEditor", LazyControlApolloGridCopyEditor],
["ControlApolloGridHeadingEditor", LazyControlApolloGridHeadingEditor],
["ControlApolloGridQuoteEditor", LazyControlApolloGridQuoteEditor],
["ControlApolloGridRichTextEditor", LazyControlApolloGridRichTextEditor],
["ControlHeadline", LazyControlHeadline],
["ControlMedia", LazyControlMedia],
["ControlQuote", LazyControlQuote],
["ControlRte", LazyControlRte],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
