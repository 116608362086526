<template>
  <div>
    <NuxtLayout :name="layout">
      <div
        class="error-page"
        :class="{
          'error-404': error.statusCode === 404
        }">
        <breadcrumb-menu
          v-if="error.statusCode === 404"
          :content="errorContent" />

        <div class="container">
          <div class="error-page__header">
            <a-icon icon="fa:solid:triangle-exclamation" />
            <h1>{{ heading }}</h1>
          </div>

          <div class="error-page__description">
            <h3>
              {{ description }}
            </h3>

            <div
              v-if="isDev"
              class="error-page__dev-info">
              <p>{{ error.message }}</p>
              <p v-if="error.data">
                {{ error.data }}
              </p>
            </div>

            <a-button
              variant="outline"
              @click="goBack">
              Back
            </a-button>
          </div>

          <div
            v-if="links && links.length"
            class="error-page__links">
            <strong>Where to go next</strong>
            <ul>
              <li
                v-for="link in links"
                :key="link.key">
                <template v-if="link.url">
                  <a-icon icon="fa:solid:chevron-right" />
                  <nuxt-link
                    :to="link.url"
                    :target="link.target"
                    :external="isExternalLink(link.url)">
                    {{ link.label }}
                  </nuxt-link>
                </template>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import type { Error404 } from "~/models/generated/Error404";
import type { NuxtError } from "#app";

const props = defineProps<{
  error : NuxtError
}>();

const layout = props.error.statusCode === 404 ? "default" : false;

useHead( {
  title: () => `Error ${props.error.statusCode}`,
} );

console.error( props.error );

const route = useRoute();

const isDev = ref( ( import.meta.client && window.location.href.includes( "dev" ) ) || import.meta.dev || !!route.query.apolloDebug );

const { getByTypeSingle } = useContentDeliveryResource();
const { data: errorContent } = await getByTypeSingle<Error404>( "error404" );

const links = computed( () => errorContent.value?.properties.links ?? [] );

const is404 = computed( () => props.error.statusCode === 404 );

const heading = computed( () => {
  let result = null;

  if ( is404.value && errorContent.value ) {
    result = errorContent.value.properties.heading;
  }

  return result ?? `Error ${props.error.statusCode}`;
} );

const description = computed( () => {
  let result = null;

  if ( is404.value && errorContent.value ) {
    result = errorContent.value.properties.description;
  }

  return result ?? "It's us, not you - there seems to be a problem with this page.";
} );

const router = useRouter();

async function goBack () {
  try {
    const { history } = router.options;

    let previousPage = history.state.back as string;

    if ( !previousPage ) {
      const previous = new URL( document.referrer );
      previousPage = previous.pathname;
    }
    window.location.href = previousPage;
  } catch ( err : any ) {
    console.error( "There was an error trying to go back", err );
  }
}

</script>

<style lang="scss" scoped>
.error-page {
  margin-top: var( --s-4 );

  &:not( .error-404 ) {
    min-height: 100dvh;
    display: grid;
    place-items: center;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: var( --s-5 );

    h1 {
      margin: 0;
      color: var( --color-text );
    }

    svg {
      font-size: var( --s-7 );
      color: var( --color-danger-500 );
    }
  }

  &__links {
    strong {
      color: var( --color-gray-300 );
      text-transform: uppercase;
      font-size: var( --s-5 );
      position: relative;
      margin-bottom: var( --s-4 );
      display: block;

      &::before {
        position: absolute;
        top: -10px;
        content: "";
        width: 45px;
        height: 4px;
        background-color: var( --color-gray-300 );
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: var( --s-3 );
    }

    li {
      display: flex;
      align-items: center;
      gap: var( --s-5 );

      svg {
        color: var( --color-danger-500 );
      }

      a {
        font-weight: bold;
        font-size: var( --s-5 );
        color: var( --color-text );
        text-decoration: none;

        &:hover {
          color: var( --color-danger-500 );
          text-decoration: underline;
        }
      }
    }
  }

  &__description {
    ::v-deep( button ) {
      svg {
        display: none;
      }
    }
  }

  &__dev-info {
    margin-top: var( --s-5 );
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: var( --s-6 );
  }
}
</style>
