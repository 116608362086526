export function useGeoTrackingResource () {
  function getLocation () {
    const url = "/api/geo-tracking/location";

    const options_ = {
      headers: {
        Accept: "application/json",
      },
    };

    return $apiFetch<string | null>( url, options_ );
  }

  return {
    getLocation,
  };
}
