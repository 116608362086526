import revive_payload_client_k9Lpmo4TPl from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@8.57.1_ioredis@5._hk3bpk6nqn3o462uhrjipbjdb4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_sE0p04skzY from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@8.57.1_ioredis@5._hk3bpk6nqn3o462uhrjipbjdb4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7PPFmzYv59 from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@8.57.1_ioredis@5._hk3bpk6nqn3o462uhrjipbjdb4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import logger_MSL00Dw8rq from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.807_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_ujwvp2uzbotr3qq3dkryvlazoe/node_modules/@edco/apollo/dist/runtime/plugins/logger.js";
import payload_client_OhZqnWNUuQ from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@8.57.1_ioredis@5._hk3bpk6nqn3o462uhrjipbjdb4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_BlhyUnmOr4 from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@8.57.1_ioredis@5._hk3bpk6nqn3o462uhrjipbjdb4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_rcCg0mG9j2 from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@8.57.1_ioredis@5._hk3bpk6nqn3o462uhrjipbjdb4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_gDRPYwaMf5 from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@8.57.1_ioredis@5._hk3bpk6nqn3o462uhrjipbjdb4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_qCnZyFm4gO from "/opt/node_app/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/node_app/app/.nuxt/components.plugin.mjs";
import prefetch_client_lCwPoq2jxz from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@8.57.1_ioredis@5._hk3bpk6nqn3o462uhrjipbjdb4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_1ljH4vtQLR from "/opt/node_app/app/node_modules/.pnpm/@sentry+nuxt@8.34.0-internal.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelem_ie4af7hbdok3dt7g3haejb2x7y/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/node_app/app/.nuxt/sentry-client-config.mjs";
import veevalidate_temp_0JfRvA41IH from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.807_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_ujwvp2uzbotr3qq3dkryvlazoe/node_modules/@edco/apollo/dist/runtime/plugins/veevalidate-temp.js";
import hydrated_7DGSsLyJ3f from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.807_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_ujwvp2uzbotr3qq3dkryvlazoe/node_modules/@edco/apollo/dist/runtime/plugins/hydrated.js";
import dialogs_nsz09WTy9B from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.807_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_ujwvp2uzbotr3qq3dkryvlazoe/node_modules/@edco/apollo/dist/runtime/plugins/dialogs.js";
import tooltip_S5UPVZquNW from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.807_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_ujwvp2uzbotr3qq3dkryvlazoe/node_modules/@edco/apollo/dist/runtime/plugins/tooltip.js";
import gtag_lJoTVZ5fqg from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.807_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_ujwvp2uzbotr3qq3dkryvlazoe/node_modules/@edco/apollo/dist/runtime/plugins/gtag.js";
import theme_8rRWTlERRK from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.807_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_ujwvp2uzbotr3qq3dkryvlazoe/node_modules/@edco/apollo/dist/runtime/plugins/theme.js";
import middleware_jTxahMFrMf from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.807_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_ujwvp2uzbotr3qq3dkryvlazoe/node_modules/@edco/apollo/dist/runtime/plugins/middleware.js";
import metrics_5MMuBEKZZj from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.807_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_ujwvp2uzbotr3qq3dkryvlazoe/node_modules/@edco/apollo/dist/runtime/plugins/metrics.js";
import plugin_Jr1CwJz1D1 from "/opt/node_app/app/src/modules/gtm/runtime/plugin.ts";
import logging_82983bmFHh from "/opt/node_app/app/src/plugins/logging.ts";
export default [
  revive_payload_client_k9Lpmo4TPl,
  unhead_sE0p04skzY,
  router_7PPFmzYv59,
  logger_MSL00Dw8rq,
  payload_client_OhZqnWNUuQ,
  navigation_repaint_client_BlhyUnmOr4,
  check_outdated_build_client_rcCg0mG9j2,
  chunk_reload_client_gDRPYwaMf5,
  plugin_vue3_qCnZyFm4gO,
  components_plugin_KR1HBZs4kY,
  prefetch_client_lCwPoq2jxz,
  sentry_client_1ljH4vtQLR,
  sentry_client_config_o34nk2sJbg,
  veevalidate_temp_0JfRvA41IH,
  hydrated_7DGSsLyJ3f,
  dialogs_nsz09WTy9B,
  tooltip_S5UPVZquNW,
  gtag_lJoTVZ5fqg,
  theme_8rRWTlERRK,
  middleware_jTxahMFrMf,
  metrics_5MMuBEKZZj,
  plugin_Jr1CwJz1D1,
  logging_82983bmFHh
]