import type { HeadlessItem } from "@edco/apollo";

type SiteDefinition = {
  id: "uk" | "international",
  domain: string,
  locales: string[]
};

const SiteDefinitions: Record<string, SiteDefinition> = {
  uk: {
    id: "uk",
    domain: "https://support.gl-assessment.co.uk",
    locales: ["en-gb", "en-ie"],
  },
  international: {
    id: "international",
    domain: "https://support.gl-education.com",
    locales: ["en"],
  },
};

export default function useHeadLinks ( headlessItem : Ref<HeadlessItem> ) {
  const route = useRoute();

  const publishedSites = computed( () => {
    if ( headlessItem.value?.data?.cultures ) {
      return Object.keys( headlessItem.value.data.cultures );
    }

    return [];
  } );

  const links = computed( () => {
    const result = [];

    let canonical: SiteDefinition = SiteDefinitions.international;
    if ( !publishedSites.value.includes( "en" ) ) {
      canonical = SiteDefinitions.uk;
    }

    let canonicalUrl = canonical.domain + route.path;

    if ( headlessItem.value?.seo?.canonicalUrl ) {
      canonicalUrl = headlessItem.value.seo?.canonicalUrl;
    }

    result.push( {
      rel: "canonical",
      href: canonicalUrl,
    } );

    const allowedSites = publishedSites.value.filter( ( s ) => !canonical.locales.includes( s ) );
    for ( let i = 0; i < allowedSites.length; i += 1 ) {
      const site = allowedSites[i];

      const foundDefinition = Object.values( SiteDefinitions )
        .find( ( s ) => s.locales.includes( site ) );

      if ( foundDefinition ) {
        for ( let j = 0; j < foundDefinition.locales.length; j += 1 ) {
          const locale = foundDefinition.locales[j];
          result.push( {
            rel: "alternate",
            hreflang: locale,
            href: foundDefinition.domain + route.path,
          } );
        }
      }
    }

    return result;
  } );

  useHead( {
    link: links,
  } );

  return links;
}
